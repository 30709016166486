import { Logo } from './logo'
import { buttonVariants } from './ui/button'
import { cn } from '#app/utils/misc.js'

import { ROUTE_PATH as LOGIN_PATH } from '#app/routes/onboarding+/username'
import { ROUTE_PATH as DASHBOARD_PATH } from '#app/routes/auth+/login'
import { Link } from '@remix-run/react'
import { useTranslation } from 'react-i18next'

export function MainNavigation({ isAuthenticated }: { isAuthenticated: boolean }) {
  const { t } = useTranslation()
  return (
    <nav className="sticky top-0 z-50 h-28 w-full border-b bg-background">
      <div className="container flex h-full items-center justify-between px-20">
        <Link to="/" prefetch="intent" className="flex h-8 items-center gap-1">
          <Logo />
        </Link>
        <div className="flex items-center gap-4">
          <div className="flex items-center gap-6">
            <Link
              to="#"
              className={cn(
                buttonVariants({ variant: 'link', size: 'sm' }),
                'group hidden gap-3 text-base font-medium text-primary/80 hover:text-primary hover:no-underline lg:flex',
              )}>
              {t('features_nav')}
            </Link>
            <Link
              to="#"
              className={cn(
                buttonVariants({ variant: 'link', size: 'sm' }),
                'group hidden gap-3 text-base font-medium text-primary/80 hover:text-primary hover:no-underline lg:flex',
              )}>
              {t('about_nav')}
            </Link>
          </div>
          <Link
            to={isAuthenticated ? DASHBOARD_PATH : LOGIN_PATH}
            className={cn(
              buttonVariants({ size: 'sm' }),
              'h-12 rounded-lg bg-[#091834] text-base font-bold',
            )}>
            {isAuthenticated ? t('dashboard_title') : t('start_now_cta')}
          </Link>
        </div>
      </div>
    </nav>
  )
}
